// Here you can add other styles
// .c-app {
//     max-width: 1366px ;
//     align-items: center;
//     margin-left: auto;
//     margin-right: auto;
// }
.no-border-bottom {
    border-bottom: 0px !important;
}

.listNotif {
    white-space: normal !important;
    width: 40rem !important;
    height: 4rem !important;
    overflow-y: scroll;
    scrollbar-color: rgb(14, 12, 15)
}

.noPaddingBottom {
    padding-bottom: 0 !important;
}

.listNotifParent {
    white-space: normal !important;
    width: 40rem !important;
    height: 20rem !important;
    overflow-y: scroll;
    scrollbar-color: rgb(84, 70, 99) !important;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0
    }

    100% {
        background-position: 800px 0
    }
}

.animated-background {
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #ebedef;
    background: linear-gradient(to right, #ebedef 8%, rgb(207, 162, 206) 18%, #ebedef 33%);
    background-size: 800px 104px;
    height: 70px;
    position: relative;
}


.parentSelectEuroUSD {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-end
}

.parentSubHeader {
    flex-wrap: nowrap;
    min-width: 100%;
    display: inline-flex;
    max-height: 56px;
}

.statHeader {
    min-width: 10rem;
}

.displaySell {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: baseline;
}

.textUnlock {
    color: rgb(236, 13, 13);
    background: white;
    border: white;
}

.statTexto {
    color: #888888 !important;
    margin: 0.5rem !important;
}

.parentListBot {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.childListBot {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.addAPICard {
    max-width: 480px;
    margin: 8px;
}

.ListAPICard {
    max-width: 480px;
    margin: 8px;
}

.centerTitle {
    text-align: center;
}

.HeaderMail {
    color: white;
    border-radius: 0.8rem;
    // background: border-box;
    // height: 2.8rem
}

.HeaderTape {
    color: white;
    vertical-align: super;
    // padding-left: 8px;
}

.c-main {
    padding-top: 16px;
}

.settingsPage {
    height: 100%;
}

html:not([dir=rtl]) .c-sidebar-nav-icon:first-child {
    margin-left: -1.5rem !important;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: rgb(50, 56, 105)
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    background: #06179a;
    border-radius: 0.8rem;
}

.c-sidebar .c-sidebar-nav-link:hover {
    border-radius: 0.8rem;
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
    color: #000;
}

.toggle-active {
    height: 2rem !important;
    background-color: #ebedef;
    border-radius: 0.8rem
}

.c-sidebar {
    background: #ebedef;
    left: auto !important
}

.c-sidebar .c-sidebar-nav-title {
    color: #000;
}

.c-sidebar-nav-item {
    border-radius: 0.8rem;
    background: white;
    margin: 0.4rem;
}

.c-sidebar .c-sidebar-brand {
    background: #06179A;
    // border-radius: 0 0 0.8rem 0.8rem;

}

.c-sidebar .c-sidebar-minimizer {
    background: linear-gradient(145deg, #06179A 0%, #93168E 100%);
    border-radius: 0.8rem 0.8rem 0 0
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-fixed:not(.c-sidebar-right) {
    display: none;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right),
html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    display: inherit !important;
}

.titre {
    color: #0A22DC
}

.card-header {
    font-weight: 500;
}

.card-header-margin {
    margin-right: -1.25rem !important
}

.card-header-margin2 {
    margin-right: -1.25rem !important
}

.detailsInfoLeft {
    position: relative;
    float: left;
}

.detailsPopUp {
    left: 0;
    top: 0;
    z-index: -1;
    background-color: #fff;
    opacity: 0.20;
    pointer-events: none
}

.pointer-disable {
    pointer-events: none !important
}

.padding8bis {
    padding: 8px;
    position: relative;
    top: -4px;
}

.detailsPopUpContent {
    top: 320px;
    position: relative;
    pointer-events: auto !important;
    z-index: 1 !important;
    text-align: center;
    opacity: 1 !important
}

.btnCircle {
    border-radius: 50%;
}

.headTableCenter {
    text-align: center;
}

.headTableBlueColor {
    color: blue;
}

.settingsB {
    position: relative;
    left: 90%
}

.PopUp {
    position: fixed;
    top: 30%;
    left: 40%;
    max-width: 480px;
    border: 1px solid #011627;
    border-radius: 5px;
    background-color: #FDFFFC !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #011627;
    z-index: 11 !important;
    opacity: 1 !important;
    pointer-events: auto !important;
}

.hidden {
    display: none;
}

.simuResLeft {
    position: relative;
    float: left;
}

.btnRight {
    position: relative;
    float: right
}

.margin8 {
    margin: 7px
}

.no-border1 {
    border: 0px !important;
}

.no-border {
    border: 0px !important;
    margin-top: 45px;
}

.padding8 {
    padding-top: 8px;
}

.padding0 {
    padding: 0px 0px 0px 0px;
    margin-top: -4px;
}

.porfolioHeader {
    min-width: 5rem;
}

.porfolioHeader {
    min-width: 100px;
    color: white;
    margin: 6%;
    font-size: calc(0.5em + 0.4vw)
}

.yexoFontResponsive {
    font-size: calc(0.5em + 0.4vw) !important
}
.labelType{
    margin-top: 5px;
    font-size: 11px;
    margin-left: 5px;
}
.statCarousel {
    width: 100%;
    height: 220px;
}

.beginnerSelectCard {
    width: 100%;
}

.porfolioCardHeader {
    margin-bottom: 0px
}

.porfolioSelect {
    padding: 6px 0px;
}

.detailsInfoRigth {
    text-align: right;
}

.bold {
    font-weight: bold;
}

.card-body-withour-padding-top {
    padding-top: 0 !important;
}

.simuResRigth {
    text-align: right;
}

.detailsInfoCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-image: url('../assets/images/background.png');
}

.detailsBox {
    // max-width: 450px;
}

.detailsAnalysis {
    font-weight: 500;
}

.detailsAnalysisItem {
    font-weight: 200 !important
}

.detailsTab {
    padding-top: 8px;
}

.detailsCard {
    min-height: 350px;
}

.rowLogs {
    padding: 10px 0;
}

.divHeadInstalled {
    padding: 0 calc(1em + 1vw);
}

.form-control:disabled {
    background-color: white;
}

.badgeHeader {
    font-size: 18px;
    min-width: 75px;
    margin: 0px 16px
}

.badgePool {
    position: absolute;
    background: #6d1691;
    height: 1.5rem;
    left: 1.5rem;
    top: 0.15rem;
    width: 1.5rem;
    text-align: center;
    line-height: 1.5rem;
    /* font-size: 1rem; */
    border-radius: 50%;
    color: white
}

.settingPatternValidate {
    margin: 18px;
}

.buttonSwitchHeader {
    margin-top: 16px;
    opacity: 0.3;
}

.closeChart {
    position: absolute;
    right: 16px;
    z-index: 2;
    opacity: 0.5;

}

.settingPatternTitle {
    top: -16px;
    position: relative;
}

.cancel-btn {
    border-color: #93168E;
    color: #93168E;
    border-width: 2px
}

.sellTypeCreate {
    padding-top: 14px
}

.noActiveBot {
    padding-bottom: 20px;
}

.cardCurrentPhase {
    position: absolute;
    top: 157px;
    white-space: nowrap;
}

.createBtn {
    height: 40px;
    margin-left: 8px;
    border-radius: 0.8rem;

}

.TradebotTypeCard {
    border-color: #06179A;
    border-width: 0.5px;
    box-shadow: 2px 3px;
}

.TradebotTypeIcone {
    height: 24px;
    padding: 3px;
}

.TradebotType {
    padding: 10px;
}

.marginLeft {
    margin-left: -4px !important;
}

.marginRight16 {
    margin-right: 16px !important;
}

.noPaddingRigth {
    padding-right: 0px !important;
}

.size75 {
    font-size: 0.55rem;
    float: left;
    margin-left: -4% !important;
    margin-top: 16%
}

.TradebotListRef {
    font-size: max(min(7px), 10px);
    white-space: nowrap;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 6px;
    margin-right: -8px;
}

.maxTitle10 {
    white-space: nowrap;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
}

.paddingTop2 {
    padding-top: 2px;
}

.marginTop4 {
    margin-top: 4px;
}

.TradebotListTypeRef {
    font-size: max(min(7px), 10px);
    white-space: nowrap;
    white-space: pre;
    margin: 0px 0px 0px -20px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}

.labelSwitch {
    font-size: 9px;
}

.labelSwitchON {
    color: rgb(58, 200, 58)
}

.labelSwitchOFF {
    color: orange;
}

.labelSwitchDiv {
    padding: 0px 0px;
}

.TradebotListAvatar {
    width: 24px;
    height: 24px;
    padding-top: 8px
}

.TradebotListChart {
    padding: 0px;
    height: 96px;
}

.TradebotListChartInside {
    height: 88px;
    width: 100%;
    padding: 16px
}

.TradbotListRowHead {
    height: 24px;
    // margin-right: -28px;
}

.Clickable {
    cursor: pointer;
    border-radius: 0.8rem;
}

.Clickable1 {
    border-radius: 0 0 0.8rem 0.8rem !important;
}

.c-switch-sm {
    width: 28px;
    height: 16px;
    padding-top: 5px
}

.c-switch-lg {
    width: 42px;
    height: 20px;
    padding-top: 5px
}

.c-switch-slider {
    background-color: #f9b115
}

.c-switch-slider::before {
    top: -1px;
    left: 2px
}

.c-switch-lg .c-switch-slider::before {
    width: 20px;
    height: 20px;
}

.notificationCreate {
    color: blue;
    text-align: center;
}

.switchSymbol {
    color: white;
    margin-right: 4px;
    opacity: 0.5
}

.noFocus:focus {
    outline: none;
    border: none;
}

.c-switch-input:focus~.c-switch-slider {
    outline: none !important;
    background: none !important;
    box-shadow: none !important
}

.html:not([dir="rtl"]) .c-switch-label .c-switch-slider::after {
    color: green;
}

.c-header {
    background: linear-gradient(145deg, #06179A 0%, #93168E 100%);
    // border-radius: 0 0 0.8rem 0.8rem;
}

.img16x16 {
    width: 16px;
    height: 16px;
}

.widgetActiveTradebotImgDelete {
    width: 16px;
    height: 16px;
    float: right;
}

.widgetActiveTradebotImgStop {
    width: 16px;
    height: 16px;
    float: left;
}

.card {
    border-radius: 0.8rem !important;
}

.card-header {
    border-radius: 0.8rem !important;
}

.card-footer.Clickable {
    border-radius: 0 0 0.8rem 0.8rem !important;
}

.card-footer {
    border-radius: 0.8rem !important;
}

.card-tradebot {
    // min-width: 320px;
    // max-width: 450px;
    border-radius: 0.8rem !important;
}

.card-title-header {
    //margin-bottom: 0.75rem;
    //font-size: calc(1em + 1vw);
    font-weight: 500;
    margin: 0px 2px 0px 2px;
    font-size: calc(1em + 1vw);
    font-weight: 500;
    overflow: hidden;
    font-size: max(min(8px), 12px);
    text-overflow: ellipsis;
    white-space: pre;
}

.c-header .c-subheader {
    //background:#ebedef ;
    background: #ebedef;
    color: #0A22DC;
    margin-top: 0px;
}

.c-subheader {
    border-radius: 0.8em;
    margin-bottom: 0.8rem;
}

.btn-header {
    background: #356AE6;
    color: white;
}

.breadcrumb {
    margin-bottom: 0;
    border-bottom: 0;
}

.chartDonut {
    height: 160px;
    max-width: 320px;
    font-weight: 640;
    font-size: 32px;

}

.chartDonut-legend-item-label {
    overflow: hidden;
    text-overflow: ellipsis;
}

.badge1 {
    display: inline-block;
    padding: 16% 4%;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 24px;
}

.badge2 {
    display: inline-block;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DivTradingView {
    height: 130px;
}

.thumbnail {
    width: 100%;
    height: auto;
    border-radius: 50em !important;
}

.backgroundBlue {
    background-color: #06179A;
    color: white;
}



.displayUserList {
    background: ghostwhite;
    // opacity: 51%;
    color: black;
    /* margin: 4px -8px; */
    padding: 4px;
}

.backgroundBlueDark {
    background-color: #21073a;
    color: white;
}

.text-color-blue {
    color: #06179A !important
}

.hedearWithoutborder {
    border-bottom: 0px
}

.imgMainGetStart {
    height: 92px;
}

.imgMainGetStartParent {
    display: flex;
    margin: 1rem;
    justify-content: space-evenly;
    align-items: center;
}

.historyAvatar {
    width: 16px;
    height: 25px;
    opacity: 1 !important;
}

.gradActBot {
    padding: 7px;
    background: #1f1498 !important;
    background: linear-gradient(145deg, #06179A 0%, #93168E 100%) !important;
    border-color: #1f1498 !important;
    ;
}

.boxPerfDashboard {
    min-height: 107px;
}

.gradMenBot {
    background: linear-gradient(145deg, #06179A 0%, #93168E 100%) !important;
    overflow: hidden;
}

.headCenter {
    text-align: center;
    border-bottom: 0px
}

.widgetTicketH {
    height: 85px;
    margin-right: -10px
}

.tickerTape {
    height: 55px;
}

.tv-embed-widget-wrapper__body {
    height: 80px !important;
    border: 0px !important;
}

.widgetSimu {
    padding: 10px;
    font-size: calc(0.5em + 0.5vw) !important
}

.widgetSimuStop {
    padding: 10px;
    background-color: #e66363;
}

::-webkit-scrollbar {
    width: 10px;
}

.topSettings {
    margin: -4px
}

.topSettingsHeader {
    background-color: #356ae6;
    height: 32px;
    color: white;
    font-weight: bold;
    font-size: 10px;
    margin-top: -12px
}

.topTen a:hover {
    text-decoration: none;
}

.c-switch-slider :hover {
    background-color: none !important;
}

.scrollable {
    overflow-y: scroll
}

.scrollablex {
    overflow-x: scroll
}

.tradingview-widget-copyright {
    display: none;
}

.label-39Xi8p6T.top-39Xi8p6T {
    display: none;
}

.filter-blue-l1 {
    filter: invert(10%) sepia(97%) saturate(5954%) hue-rotate(226deg) brightness(71%) contrast(124%);
}

.filter-blue-l2 {
    filter: invert(11%) sepia(75%) saturate(5002%) hue-rotate(269deg) brightness(81%) contrast(98%);
}

.filter-blue-l3 {
    filter: invert(13%) sepia(87%) saturate(4543%) hue-rotate(294deg) brightness(83%) contrast(92%);
}

.onBoardStartButton {
    position: fixed;
    bottom: 0px;
    right: 0px;
}

.onBoardHelpCard {
    max-width: 344px;
    height: 100%;
}

.onBoardMainBodyCard {
    height: 100%;
}

.onBoard-ol-head ol {
    counter-reset: my-awesome-counter;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;
    align-content: flex-start
}

.onBoard-ol-head ol li {
    counter-increment: my-awesome-counter;
    position: relative;
}

.onBoard-ol-head ol li::before {
    content: counter(my-awesome-counter);
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    --size: 32px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    transform: rotate(-10deg);
    background: #06179A;
    border-radius: 50%;
    text-align: center;
    box-shadow: 1px 1px 0 #999;
}

.modal-content {
    border-radius: 0.8rem !important;
}

.managerDashboardLeft {
    background: linear-gradient(23deg, #3e3685, #89168f);
}

.managerDashboardCenter {
    background: linear-gradient(23deg, #3c3b7e, #3c3b7e);
}

.managerDashboardRight {
    background: linear-gradient(320deg, #0d9fe5, #0d9fe5)
}

.managerDashboardImg {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: 8px;
}

.managerAvatarDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(235, 237, 239);
}

.inputSearch {
    width: 40%;
    min-width: 10rem;
    // border-radius: 10px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #3c3b7e;
    padding: 10px;
    margin-bottom: 1rem;
  }